<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-toolbar color="primary darken-1" dark>

          <v-card-title>
            <span class="">{{
                (editedIndex === -1 ? $t("add") : $t("edit")) +
                " " +
                $t("schools.school")
              }}</span>

          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container v-if="editedItem">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.name"
                  :rules="[rules.requiredLength]"
                  :label="$t('schools.schoolName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  :rules="[rules.requiredLength]"
                  v-model="editedItem.manager_name"
                  :label="$t('schools.manager_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.manager_phone"
                  :label="$t('schools.manager_phone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.locality"
                  :label="$t('schools.locality')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.district"
                  :label="$t('schools.district')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.village"
                  :label="$t('schools.village')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.closest_point"
                  :label="$t('schools.closest_point')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" v-if="$store.getters.isInRole(101) && this.editedIndex === -1">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.domain"
                  :rules="[rules.domain]"
                  :label="$t('schools.domain')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="directorates"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.directorate_id"
                  :rules="[rules.requiredLength]"
                  :label="$t('schools.directorate')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="owner"
                  item-text="text"
                  item-value="value"
                  v-model="editedItem.owner"
                  :rules="[rules.requiredLength]"
                  :label="$t('schools.ownership')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4"
                     v-if="$store.getters.isInRole(101)">
                <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="servers"
                  item-text="domain"
                  item-value="id"
                  v-model="editedItem.server_id"
                  :label="$t('schools.server')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  return-object
                  outlined
                  chips
                  multiple
                  :clearable="true"
                  :deletable-chips="true"
                  hide-details
                  persistent-hint
                  :items="schoolTypesList"
                  item-text="school_type"
                  item-value="id"
                  v-model="editedItem.types"
                  :rules="[rules.requiredLength]"
                  :label="$t('schools.stage')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  return-object
                  outlined
                  chips
                  multiple
                  :clearable="true"
                  :deletable-chips="true"
                  hide-details
                  :items="schoolsGroups"
                  v-model="editedItem.groupschs"
                  :label="$t('schoolGroups.schoolGroups')"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  return-object
                  outlined
                  chips
                  multiple
                  :clearable="true"
                  :deletable-chips="true"
                  hide-details
                  :items="supervisors"
                  v-model="editedItem.supervisors"
                  :label="$t('supervisors.supervisors')"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12" v-if="$store.getters.isInRole(120)">
                <v-select
                  outlined
                  chips
                  :clearable="true"
                  :deletable-chips="true"
                  hide-details
                  :items="agencies"
                  v-model="editedItem.agency_id"
                  :label="$t('schools.agency')"
                  item-text="title"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="6" md="3">
                <v-switch
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.free"
                  :label="$t('schools.isFree')"
                ></v-switch>
              </v-col>
              <v-col cols="6" md="3">
                <v-switch
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.show_in_statistics"
                  :label="$t('schools.statistics')"
                ></v-switch>
              </v-col>
              <v-col cols="6" md="3">
                <v-switch
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.test"
                  :label="$t('schools.test')"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-if="editedItem.free"
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.free_cause"
                  :rules="[rules.requiredLength]"
                  :label="$t('schools.reason')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  type="number"
                  :rules="[rules.requiredLength]"
                  v-model="editedItem.student_expected_count"
                  :label="$t('schools.student_expected_count')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.comment"
                  :label="$t('schools.notes')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!hideSave"
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid || submitLoading"
            :loading="submitLoading"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: [
    "onSave",
    "hideSave",
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
    "directorates"
  ],
  data() {
    return {
      valid: true,
      submitLoading: false,
      agencies: [],
      servers: [],
      schoolsGroups: [],
      supervisors: [],
      schoolTypes: [],
      schoolTypesList: [],
      selectedSchoolsGroups: [],
      selectedSchoolTypes: [],
      owner: [
        {
          text: this.$t("ownershipTypes.private"),
          value: "private_schools",
        },
        {
          text: this.$t("ownershipTypes.public"),
          value: "public_schools",
        },
      ],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        requiredLength: (value) => !!value || (value && value.length > 0 && value.length < 255) || this.$t("ThisFieldIsRequired"),
        domain: (value) => (value == null || /^[a-z\d-]+$/i.test(value)) || this.$t("InvalidInput"),
      },
      rulesWithLength: {
        required: (value) =>
            (value != null && value.length < 255) ||
            this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {
    this.$axios
        .get("GetUserSchGroups", {noToast: true})
        .then((response) => {
          this.schoolsGroups = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });

    if (this.$store.getters.isInRole(120)) {
      this.$axios
          .get("agencies", {params: {noToast: true, all: true}})
          .then((response) => {
            this.agencies = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
    }

    this.$store
        .dispatch("GetSchoolsTypes")
        .then((data) => {
          this.schoolTypes = data;
          this.schoolTypesList = [
            {
              id: this.schoolTypes[0].id,
              school_type: this.$t(
                  "schoolTypes." + this.schoolTypes[0].school_type
              ),
            },
            {
              id: this.schoolTypes[1].id,
              school_type: this.$t(
                  "schoolTypes." + this.schoolTypes[1].school_type
              ),
            },
            {
              id: this.schoolTypes[2].id,
              school_type: this.$t(
                  "schoolTypes." + this.schoolTypes[2].school_type
              ),
            },
            {
              id: this.schoolTypes[3].id,
              school_type: this.$t(
                  "schoolTypes." + this.schoolTypes[3].school_type
              ),
            },
            {
              id: this.schoolTypes[4].id,
              school_type: this.$t(
                  "schoolTypes." + this.schoolTypes[4].school_type
              ),
            },
            {
              id: this.schoolTypes[5].id,
              school_type: this.$t(
                  "schoolTypes." + this.schoolTypes[5].school_type
              ),
            },
          ];
        })
        .finally(() => {
          this.loading = false;
        });

    if (this.$store.getters.isInRole(101)) {
      axios.get("servers")
          .then((response) => {
            this.servers = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
    }

    this.$axios
        .get("GetSupervisors", {noToast: true})
        .then((response) => {
          this.supervisors = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
  },
  computed: {},
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    save() {
      this.loading = true;

      var val = this.$refs.form.validate();

      if (this.editedItem.types.length == 0) {
        this.$toast.warning(this.$t("SelectAtLeastOneSchoolType"));
        this.loading = false;
        return;
      }
      if (this.editedItem.free == false) {
        this.editedItem.free_cause = null;
      }
      if (val) {
        this.submitLoading = true;
        //MySql accepts 1 or 0 for boolean values
        this.editedItem.free = this.editedItem.free == true ? 1 : 0;

        if (
            this.editedItem.supervisors != null &&
            this.editedItem.supervisors.length > 0
        ) {
          this.editedItem.supervisors = this.editedItem.supervisors.map(
              (a) => a.id
          );
        } else {
          this.editedItem.supervisors = [];
        }

        if (
            this.editedItem.groupschs != null &&
            this.editedItem.groupschs.length > 0
        ) {
          this.editedItem.school_group = this.editedItem.groupschs.map(
              (a) => a.id
          );
        } else {
          this.editedItem.school_group = [];
        }

        if (this.editedItem.types != null && this.editedItem.types.length > 0) {
          this.editedItem.school_type = this.editedItem.types.map((a) => a.id);
        } else {
          this.editedItem.school_type = [];
        }

        if (this.editedIndex > -1) {
          this.$axios
              .post("UpdateSchool/" + this.editedItem.id, this.editedItem)
              .then((response) => {
                if (response.data.status == "Successful") {
                  this.onClose();
                }
              })
              .finally(() => {
                this.$emit("refreshTable");
                this.loading = false;
                this.submitLoading = false;
              });
        } else {
          this.$axios
              .post("AddSchool", this.editedItem)
              .then((response) => {
                if (response.data.status == "Successful") {
                  this.onClose();
                  this.$emit("refreshTable");
                }
              })
              .finally(() => {
                this.$emit("refreshTable");
                this.loading = false;
                this.submitLoading = false;
              });
        }
      }
    },
  },
};
</script>

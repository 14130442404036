<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-row>
        <v-col cols="6" md="2">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            outlined
            dense
            clearable
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            outlined
            hide-details
            @change="refreshTable"
            dense
            persistent-hint
            clearable
            v-model="governorate_id"
            :items="governorates"
            item-text="name"
            item-value="id"
            :label="$t('directorates.governorate')"
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            outlined
            hide-details
            @change="refreshTable"
            dense
            persistent-hint
            clearable
            v-model="directorate_id"
            :items="directorates_filtered"
            item-text="name"
            item-value="id"
            :label="$t('directorates.directorate')"
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            :label="$t('schools.schoolYear')"
            :items="schoolYears"
            outlined
            @change="refreshTable"
            dense
            clearable
            v-model="year_id"
            item-text="school_year"
            item-value="id"
            hide-details
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            outlined
            dense
            clearable
            hide-details
            persistent-hint
            :items="status"
            item-text="label"
            item-value="value"
            v-model="status_filter"
            @change="refreshTable"
            :label="$t('schoolRenew.status')"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="SchoolRenews"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [50, 100, 200, -1]
        }"
        :server-items-length="total"
        :options.sync="options"
        @update:options="refreshTable"
      >
        <template v-slot:[`item.school.name`]="{ item }">
          {{ item.school.name }}
          <v-btn
            v-if="$store.getters.isInRole(101)"
            color="info"
            @click="ViewItem(item)"
            x-small
          >
            التفاصيل
          </v-btn>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | format_date_time }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <span v-if="item.status === 'accepted' || item.status === 'rejected'">{{
              item.updated_at | format_date_time
            }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.comments && item.comments.length > 0 ? 'warning' : 'dark'"
                @click="viewItemNote(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-alert-decagram
              </v-icon>
            </template>
            <span> {{ $t("schoolRequest.note") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item.status === 'pending'" color="info" small label>
            {{ $t('schoolRenew.pending') }}
          </v-chip>
          <v-chip v-else-if="item.status === 'accepted'" color="success" small label>
            {{ $t('schoolRenew.accepted') }}
          </v-chip>
          <v-chip v-else-if="item.status === 'rejected'" color="error" small label>
            {{ $t('schoolRenew.rejected') }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row v-if="item.status === 'pending'" style="flex-flow: row;">
            <v-btn
              color="success"
              @click="acceptItem(item)"
              small
            >
              <v-icon>
                mdi-check
              </v-icon>
              {{ $t("schoolRenew.accept") }}
            </v-btn>
            <v-btn
              class="mx-1"
              color="error"
              @click="rejectItem(item)"
              small
            >
              <v-icon>
                mdi-close
              </v-icon>
              {{ $t("schoolRenew.reject") }}
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card>


    <confirm-dialog
      :openDialog="dialogAccept"
      :onClicked="updateStatus"
      :payload="'accepted'"
      :dialog-title="$t('schoolRenew.accept')"
      tool-bar-color="success"
      :onClose="closeAll"
    />

    <confirm-dialog
      :openDialog="dialogReject"
      :onClicked="updateStatus"
      :payload="'rejected'"
      :dialog-title="$t('schoolRenew.reject')"
      tool-bar-color="error"
      :onClose="closeAll"
    />
    <note-dialog
      :dialog.sync="dialogViewNote"
      :request="request"
      @refreshTable="refreshTable"
    ></note-dialog>
    <Dialog
      :onClose="closeAll"
      :dialog="dialogView"
      :editedItem="school"
      :editedIndex="editedIndex"
      :valid="valid"
      :resetValidation="resetValidation"
      :directorates="directorates"
      :hide-save="true"
      @refreshTable="refreshTable"
    />
    <dialog
      :dialog.sync="dialogView"
      :school="school"
      @refreshTable="refreshTable"
    ></dialog>
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import NoteDialog from "./NoteDialog.vue";
import Dialog from "../Schools/Dialog.vue";
import {ObjectToQuery} from "@/plugins/helper";

export default {
  components: {ConfirmDialog, NoteDialog, Dialog},
  data() {
    return {
      loading: true,
      valid: true,
      dialogReject: false,
      dialogViewNote: false,
      dialogAccept: false,
      dialogView: false,
      status_filter: null,
      governorate_id: null,
      directorate_id: null,
      year_id: null,
      school: null,
      request: null,
      total: 0,
      options: {},
      editedIndex: -1,
      schoolYears: [],
      directorates: [],
      governorates: [],
      search: "",
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
      SchoolRenews: [],
      editedItem: {
        id: null,
        name: null,
        note: null,
      },
      defaultItem: {
        id: null,
        name: null,
        note: null,
      },
      resetValidation: 0,
    };
  },
  computed: {
    directorates_filtered() {
      return this.directorates
          .filter(directorate => directorate.governorate_id === this.governorate_id || !this.governorate_id);
    },
    headers() {
      return [
        {text: this.$t("schoolRenew.school_name"), value: "school.name"},
        {text: this.$t("schoolRenew.governorate_id"), value: "governorate.name"},
        {text: this.$t("schoolRenew.directorate_id"), value: "directorate.name"},
        {text: this.$t("schoolRenew.year"), value: "year.school_year"},
        {text: this.$t("schoolRenew.status"), value: "status"},
        {text: this.$t("schoolRenew.created_at"), value: "created_at"},
        {text: this.$t("schoolRenew.updated_at"), value: "updated_at"},
        {text: this.$t("schoolRequest.note"), value: "note"},
        {text: "", value: "actions"},
      ];
    },
    status() {
      return [
        {
          value: 'pending',
          label: this.$t('schoolRenew.pending')
        },
        {
          value: 'accepted',
          label: this.$t('schoolRenew.accepted')
        },
        {
          value: 'rejected',
          label: this.$t('schoolRenew.rejected')
        },
      ];
    },
  },
  created() {
    if (!this.$store.getters.isInRole(106)) {
      this.$router.push({name: "notauthorize"});
    }
    this.loading = true;
    this.loadData()
  },
  watch: {
    search: {
      handler() {
        this.refreshTable();
      }
    }
  },
  methods: {

    acceptItem(item) {
      this.editedIndex = this.SchoolRenews.indexOf(item);
      this.editedItem = {
        id: item.id,
        name: item.school.name
      };
      this.dialogAccept = true;
    },
    ViewItem(item) {
      this.school = item.school;
      this.dialogView = true;
    },
    viewItemNote(item) {
      this.request = item;
      this.dialogViewNote = true;
    },
    rejectItem(item) {
      this.editedIndex = this.SchoolRenews.indexOf(item);
      this.editedItem = {
        id: item.id,
        name: item.school.name
      };
      this.dialogReject = true;
    },
    closeAll() {
      this.dialogReject = false;
      this.dialogAccept = false;
      this.dialogView = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    updateStatus(status) {
      this.$axios
          .post(`SchoolRenew/${this.editedItem.id}`, {status})
          .then(() => {
            this.refreshTable();
          })
      this.closeAll();
    },
    async loadData() {
      await this.getSchoolYears();
       this.getDirectorates();
       this.GetGovernorates();

    },
    async getSchoolYears() {
      console.log("fetching years")
      await this.$store
          .dispatch("GetYears")
          .then((data) => {
            this.schoolYears = data;
            if (this.schoolYears.length > 0)
              this.selectedSchoolYear = this.schoolYears[0].id;

            this.refreshTable();
          })
          .finally(() => {
            // this.loading = false;
          });
    },
    async getDirectorates() {
      await this.$store
          .dispatch("GetUserDirectorates")
          .then((data) => {
            this.directorates = data;
            // this.directorates.push({
            //   id: null,
            //   name: this.$t("all"),
            // });
          })
          .finally(() => {
            // this.loading = false;
          });
    },
    async GetGovernorates() {
      await this.$store
          .dispatch("GetUserGovernorates")
          .then((data) => {
            this.governorates = data;
          })
          .finally(() => {

          });
    },
    async refreshTable() {

      this.dialog = false;
      this.dialogView = false;
      this.dialogTransfer = false;
      this.dialogReject = false;
      this.loading = true;
      let conditions = {
        ...this.options,
        search: this.search
      };
      if (this.status_filter) {
        conditions.status = this.status_filter;
      }
      if (this.year_id) {
        conditions.year_id = this.year_id;
      }
      if (this.directorate_id) {
        conditions.directorate_id = this.directorate_id;
      }
      if (this.governorate_id) {
        conditions.governorate_id = this.governorate_id;
      }
      const query = ObjectToQuery(conditions);
      await this.$axios
          .get(`SchoolRenew/?${query}`, {noToast: true, failureToast: true})
          .then((response) => {
            this.SchoolRenews = response.data.data.data;
            this.total = response.data.data.total;
          })
          .finally(() => {
            this.$store.dispatch('GetUserAlerts', true);
            this.loading = false;
          });
    },
  },
};
</script>
